// TODO: Add tests for supported regions for security that changes to this file will not cause regressions

const US_REGION = 'us';

function getHighbondUrlParts(currentUrl: string) {
  // Note that if Risk Register gets its own URL the below will need to change
  const highbondUrlPattern =
    /(?<protocol>http|https)?:\/\/(?<subdomain>[\w-]+)\.asset-inventory(-?)(?<region>[\w]*)\.(?<domain>highbond-gov|highbond-gov2|highbond)?(?<domainSuffix>-\w+)?\.(?<tld>local|com)$/;
  const highbondMatch = currentUrl.match(highbondUrlPattern);
  if (highbondMatch === null) return {};

  const protocol = highbondMatch?.groups?.protocol || '';
  const subdomain = highbondMatch?.groups?.subdomain || '';
  const region = highbondMatch?.groups?.region || '';
  const domain = highbondMatch?.groups?.domain || '';
  const domainSuffix = highbondMatch?.groups?.domainSuffix || '';
  const tld = highbondMatch?.groups?.tld;

  return { protocol, subdomain, domain, domainSuffix, region, tld };
}

export function getApiProxyUrl(currentUrl: string): string {
  if (currentUrl.includes('localhost')) return '';

  const { protocol, domain, domainSuffix, tld } = getHighbondUrlParts(currentUrl);
  // fix s3
  const region = null;
  const regionSuffix = region ?? US_REGION;
  const isProduction = domainSuffix === '';
  const isGovCloud = domain ? domain.includes('gov') : '';
  const apiRegion = isProduction && !isGovCloud ? `-${regionSuffix}` : '';

  const hostname = `.${domain}${domainSuffix}.${tld}`;

  return `${protocol}://apis${apiRegion}${hostname}`;
}

const isLocal = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
const protocol = isLocal ? 'http' : 'https';
const apiProxyRegionalUrl = getApiProxyUrl(window.location.origin);
const { domain, domainSuffix, tld } = getHighbondUrlParts(window.location.origin);
// to fix s3
const region = null;
// to fix s3
const subdomain = 'asset-inventory';
const appRegion = region === '' ? 'us' : region;
const host = isLocal ? process.env.REACT_APP_ROOT_HOST : `${domain}${domainSuffix}.${tld}`;
// to fix s3
const isScoped = false;
// const isScoped = host === 'highbond-s3.com' && subdomain !== 'asset-inventory';
const apiSubDomain = isScoped
  ? `assets-inventory-${subdomain?.split('asset-inventory-').pop()}-api`
  : 'assets-inventory-external';

const isGovCloud = window.location.hostname.includes('highbond-gov');
const isProductionUS = host === 'highbond.com' && appRegion === 'us';
const regionSuffix = region ? `-${region}` : '';

export const Flippers = {
  textEditor: true,
  activities: true,
  impactReports: false,
};

if (['development', 'staging'].includes(process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT as string)) {
  Flippers.activities = true;
  Flippers.impactReports = true;
}

const AppConfig = {
  assetsInventoryApiSessionUrl: `${protocol}://${apiSubDomain}${regionSuffix}.${host}`,
  assetsInventoryApiUrl: `${protocol}://${apiSubDomain}${regionSuffix}.${host}/v1`,
  assetsAttributesApiUrl: `${protocol}://asset-types-api${regionSuffix}.${host}`,
  workflowsApiUrl: `${protocol}://workflows${regionSuffix}.${host}`,
  loginUrl: `${protocol}://accounts.${host}/login`,
  tokenRefreshUrl: `${protocol}://accounts.${host}/api/token/refresh`,
  launchpadApiUrl: `${protocol}://apis${regionSuffix}.${host}/accounts/api`,
  apiProxyUrl: `${apiProxyRegionalUrl}/v1`,
  authyApiUrl: `${protocol}://authy-api${regionSuffix}.${host}`,
  pendoMonitorScript: `${protocol}://monitor${regionSuffix}.${host}/v1/monitor.js`,
  getResultsApiUrl: (subdomain: string): string => `${protocol}://${subdomain}.results${regionSuffix}.${host}/api/v3`,
  highbondDriveUrl: `${protocol}://drive-api${isProductionUS ? '-us' : regionSuffix}.${host}`,
  isLocal,
  appRegion: region,
  isGovCloud,
};

export default AppConfig;
